<template>
  <div class="app-context document">
    <div class="context" v-if="!isMobile">
      <div class="r-notice" v-if="this.form.completeYn === 'N'">
        <div class="notice">
          <p>* 진료 의뢰를 위해 아래 항목을 입력해야 됩니다.</p>
          <p>
            * 아래 항목을 입력하신 뒤 전송 버튼을 누르면, 진료 의뢰서가 수신
            병원 의사에게 전달됩니다.
          </p>
          <p>
            - 환자(또는 보호자)가 동의 절차를 완료해야 환자 정보가 온전하게
            전달이 가능합니다. (동의하지 않을 경우 가려진 상태로 전송됩니다.)
          </p>
          <p>
            - 환자(또는 보호자)가 종이로 출력된 진료 의뢰서 하단의 QR 코드를
            이용하여 동의 절차를 진행할 수 있도록 안내해주시기 바랍니다.
          </p>
          <p>
            (동의 내용: 예약 편의를 위한 휴대폰 번호 전송 및 진료 내용 회신 동의
            등)
          </p>
        </div>
      </div>
      <div class="r-notice" v-else>
        <div class="notice" style="min-width: 570px">
          <p>진료기록이 저장되어 있을경우 수정방법</p>
          <p>입력창의 내용을 원하는 내용으로 수정합니다.</p>
          <p>수정이 완료되면 하단의 '수정사항 저장하기'버튼을 클릭하여</p>
          <p>수정한 내용을 저장할 수 있습니다.</p>
          <p>수정된 진료의뢰서는 리스트에서 확인 할 수 있습니다.</p>
        </div>
      </div>

      <div class="request-form mr-16">
        <div class="r-header">
          <div class="logo-wrap">
            <img src="@/assets/images/logo_default.svg" />
          </div>
        </div>
        <div class="r-context">
          <div class="r-top">
            <div class="subject">
              <h3 v-if="this.GET_REPLY_STATUS.reply === 'Y'">되의뢰서</h3>
              <h3 v-else>진료의뢰서</h3>
            </div>
            <!--<div class="hospital-logo"></div> -->
          </div>
          <div class="r-info">
            <div class="article">
              <h5 class="a-title">수신병원</h5>
              <div class="a-content">
                <div
                  class="input-wrap choice"
                  @click="callHospitalInfo()"
                  v-if="form.completeYn !== 'Y'"
                >
                  <span class="viewer" v-text="form.receive.value"></span>
                </div>
                <div class="input-wrap readonly" v-else>
                  <span class="viewer" v-text="form.receive.value"></span>
                </div>
                <!--
                <div class="input-wrap readonly" v-else>
                  <span class="viewer" v-text="form.receive.value"></span>
                </div>
                -->
              </div>
            </div>
            <div class="article">
              <h5 class="a-title" :class="{ warn: form.subject.hasError }">
                진료과
              </h5>
              <div class="a-content">
                <div class="input-wrap" v-if="form.completeYn !== 'Y'">
                  <input
                    type="text"
                    placeholder="진료과목을 입력해주세요."
                    v-model="form.subject.value"
                    @keyup="checkSubject()"
                    @blur="checkSubject()"
                    maxlength="40"
                  />
                </div>
                <div class="input-wrap readonly" v-else>
                  <span class="viewer" v-text="form.subject.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title">의사명</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.doctor.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title" :class="{ warn: form.name.hasError }">
                환자명
              </h5>
              <div class="a-content">
                <div
                  class="input-wrap"
                  :class="{ readonly: agreeResult.agreeYn === 'Y' }"
                >
                  <input
                    type="text"
                    placeholder="환자명을 입력해주세요."
                    v-model="form.name.value"
                    @keyup="checkName()"
                    @blur="checkName()"
                    @keyup.enter="focusControl()"
                    maxlength="40"
                  />
                </div>
              </div>
            </div>
            <div class="article pat-jumin">
              <h5
                class="a-title"
                :class="{ warn: form.birth.hasError || form.gender.hasError }"
              >
                주민등록번호
              </h5>
              <div class="a-content">
                <div class="input-complex">
                  <div
                    class="input-wrap"
                    :class="{ readonly: agreeResult.agreeYn === 'Y' }"
                  >
                    <input
                      type="text"
                      placeholder="생년월일을 입력해주세요"
                      v-model="form.birth.value"
                      @keyup="checkBirth()"
                      @blur="checkBirth()"
                      maxlength="6"
                      @keyup.enter="focusControl()"
                    />
                  </div>
                  <span class="separated"></span>
                  <div
                    class="input-wrap last"
                    :class="{ readonly: agreeResult.agreeYn === 'Y' }"
                  >
                    <input
                      type="text"
                      v-model="form.gender.value"
                      @keyup="checkGender()"
                      @blur="checkGender()"
                      maxlength="1"
                      @keyup.enter="focusControl()"
                    />
                  </div>
                  <span>XXXXXX</span>
                </div>
              </div>
            </div>
            <div class="article pat-phone">
              <h5
                class="a-title"
                :class="{
                  warn: form.phone.hasError,
                }"
              >
                연락처
              </h5>
              <div class="a-content">
                <div
                  class="input-wrap"
                  :class="{ readonly: agreeResult.agreeYn === 'Y' }"
                >
                  <input
                    type="text"
                    placeholder="환자(또는 보호자) 휴대폰 번호를 입력해 주세요."
                    v-model="form.phone.value"
                    @keyup="checkPhone()"
                    @blur="checkPhone()"
                    maxlength="13"
                    @keyup.enter="focusControl()"
                  />
                </div>
                <div class="t-btn-wrap" v-if="agreeResult.agreeYn !== 'Y'">
                  <button
                    type="button"
                    class="trans"
                    @click="agreementTrans()"
                    :class="{ isActive: isAgreeTransed }"
                    style="width: 100px; height: 30px"
                  >
                    환자동의요청
                    {{/*동의 누를 시 agreementTrans() => callAgreementRequestApi() */}}
                  </button>
                  <button
                    type="button"
                    class="confirm"
                    @click="callAgreementCheckedApi()"
                    v-if="isAgreeTransed"
                    style="width: 100px; height: 30px"
                  >
                    환자동의확인
                  </button>
                </div>
                <!-- v-if='!hidedPhone' 
                <div class="input-wrap readonly" v-else>
                  <span class="viewer">
                    연락처 공유를 동의하지 않은 환자입니다.
                  </span>
                </div>
                -->
                <div
                  class="trans-agree"
                  v-if="agreeResult.agreeYn === undefined"
                >
                  <p
                    v-if="!isAgreeTransed"
                    style="color: #156f83; font-weight: bold"
                  >
                    '환자동의요청' 버튼을 클릭하신 후, 환자분께 휴대폰으로
                    전송된 URL을 클릭하여 동의 절차를 진행하도록 안내해 주세요.
                  </p>
                  <p v-else style="color: #156f83; font-weight: bold">
                    환자의 휴대폰을 통해 동의요청을 진행해 주세요. 동의요청이
                    완료된 후 환자동의확인 버튼을 클릭하면 환자의 이름과
                    전화번호가 수신병원에 수신됩니다.
                  </p>
                  <p
                    class="warn"
                    v-if="form.isCheckedAgreement.hasError"
                    v-text="form.isCheckedAgreement.errorTxt"
                  ></p>
                </div>
                <div class="trans-agree" v-if="agreeResult.agreeYn === 'Y'">
                  <p class="custom">
                    환자가 정보제공 동의에 동의하셨습니다.
                    <!-- 예약상담 전화를
                    기다리고 있을 수 있으니 전화상담을 부탁드립니다. -->
                  </p>
                </div>
                <!--<div class="trans-agree" v-if="agreeResult.agreeYn === 'N'">
                  <div class="t-btn-wrap">
                    <button
                      type="button"
                      class="trans"
                      @click="agreementTrans()"
                      :class="{ isActive: isAgreeTransed }"
                    >
                      환자동의요청
                      {{/*동의 누를 시 agreementTrans() => callAgreementRequestApi() */}}
                    </button>
                    <button
                      type="button"
                      class="confirm"
                      @click="callAgreementCheckedApi()"
                      v-if="isAgreeTransed"
                    >
                      환자동의확인
                    </button>
                  </div>
                  <p v-if="!isAgreeTransed">
                    진료의뢰서는 환자동의를 거친후 작성하실 수 있습니다.
                    환자동의를 위해 기본정보를 입력하시고 동의요청 버튼을 클릭해
                    주세요.
                  </p>
                  <p v-else>
                    환자의 휴대폰을 통해 동의요청을 진행해 주세요. 동의요청이
                    완료된 후 환자동의확인 버튼을 클리해 주세요.
                  </p>
                  <p
                    class="warn"
                    v-if="form.isCheckedAgreement.hasError"
                    v-text="form.isCheckedAgreement.errorTxt"
                  ></p>
                </div>-->
              </div>
            </div>
          </div>

          <div
            class="agree-info1"
            v-if="
              agreeResult.agreeYn === undefined || agreeResult.agreeYn === 'N'
            "
          >
            <h5>진료정보제공 동의 절차 안내</h5>
            <!-- 
            <p class="paragraph">
              본인은
              <b>{{ form.receive.value }}</b
              >에서 받은 진료 정보를 진료를 의뢰한<br />
              의료인이 진료 목적으로 요청한 경우에 이를 제공하는 것에 동의합니다
            </p>
            <p class="paragraph">
              의뢰서 수신병원에 예약 등 편의를 위해 환자 연락처(전화번호)를
              공유하는 데 동의합니다
            </p>
            -->
            <div class="patient">
              <div class="article">
                <table>
                  <tr>
                    <td>⊙</td>
                    <td>
                      <p>
                        진료 예약 편의 제공을 위해 환자(또는 보호자)의 휴대폰
                        번호를 진료의뢰 수신병원에 전송하고,환자의 진료
                        내용(검사결과, 진단 및 치료 내역)의 회신을 받기 위해서는
                        환자(또는 보호자)의 동의가 필요합니다.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>⊙</td>
                    <td>
                      <p>
                        휴대폰 번호 입력 후 '환자동의요청' (진료정보제공 동의)
                        버튼을 클릭해야, 진료의뢰서가 서버로 전송됩니다.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>⊙</td>
                    <td>
                      <p>
                        환자(또는 보호자)가 동의 절차를 완료하지 않으면,
                        수신병원 의사는 환자 정보 일부만 볼 수 있습니다.
                        환자(또는 보호자)가 동의 절차를 완료하면, 환자의 이름,
                        휴대폰 번호가 온전하게 전송됩니다.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>⊙</td>
                    <td>
                      <p>
                        동의 절차를 지금 완료하지 않은 경우에는 종이로 출력된
                        진료의뢰서 하단 QR 코드를 이용할 수 있습니다.
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <!--<div
            class="agree-info1"
            v-if="
              agreeResult.agreeYn === undefined || agreeResult.agreeYn === 'N'
            "
          >
            <h4>진료정보제공 동의서</h4>
            <!-- 
            <p class="paragraph">
              본인은
              <b>{{ form.receive.value }}</b
              >에서 받은 진료 정보를 진료를 의뢰한<br />
              의료인이 진료 목적으로 요청한 경우에 이를 제공하는 것에 동의합니다
            </p>
            <p class="paragraph">
              의뢰서 수신병원에 예약 등 편의를 위해 환자 연락처(전화번호)를
              공유하는 데 동의합니다
            </p>
            -->
          <!--<div class="patient">
              <div class="article">
                <p class="title">환자:</p>
                <div class="content">
                  <span class="viewer"></span>
                  <p>(서명)</p>
                </div>
              </div>
              <div class="article">
                <p class="title">법정대리인:</p>
                <div class="content">
                  <span class="viewer"></span>
                  <p>(서명) 주민번호:</p>
                  <span class="viewer"></span>
                  <p class="bracket">환자의 <b>(</b> <b class="last">)</b></p>
                </div>
              </div>
            </div>
          </div>
          <div class="agree-info1" v-if="agreeResult.agreeYn === 'Y'">
            <h4>진료정보제공 동의서</h4>

            <div class="patient">
              <div
                class="article"
                v-if="
                  agreeResult.rltnTypeNm === '' ||
                  agreeResult.rltnTypeNm === '본인'
                "
              >
                <p class="title">환자:</p>
                <div class="content">
                  <span class="viewer">{{ form.name.value }}</span>
                </div>
                <div class="content">
                  <span class="viewer"></span>
                  <p>(서명)</p>
                </div>
              </div>
              <div class="article" v-else>
                <p class="title">법정대리인:</p>
                <div class="content">
                  <span class="viewer">{{ agreeResult.diliNm }}</span>
                  <p>주민번호:</p>
                  <span class="viewer">{{ agreeResult.diliBirth }}</span>
                  <p class="bracket">
                    환자의 <b>({{ agreeResult.rltnTypeNm }}</b>
                    <b class="last">)</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="agree-caption first">
              <p class="paragraph">
                본인은 닥터리퍼의 진료 정보 제공 서비스를 통한 진료의뢰 및 회신,
                그에 따른 연락처 제공에 동의합니다. <br />
                여기서 회신은 <b>{{ form.receive.value }}</b
                >의 진료정보를 진료목적으로 <b>{{ form.userData.hospNm }}</b
                >에 제공하는 것을 말합니다.<br />또한 본인은 진료예약 등 편의를
                위해 <b>{{ form.receive.value }}</b
                >에 본인의 연락처를 공유하는 것에 동의합니다.
              </p>
              <div class="agree-checkbox">
                <!--
              <h5 class="c-title">환자 동의내역:</h5>
              -->
          <!--<div class="checkbox">
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.agreeYn === 'Y'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의함</p>
                  </div>
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.agreeYn === 'N'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의하지 않음</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="agree-caption">
              <p class="paragraph">
                본인은 본서비스의 품질향상 및 편의향상을 위해
                <b>{{ form.receive.value }}</b
                >의 진료 만족도 평가 수신에 동의합니다.
              </p>
              <div class="agree-checkbox">
                <!-- 
              <h5 class="c-title">환자 동의내역:</h5>
              -->
          <!--<div class="checkbox">
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.optYn1 === 'Y'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의함</p>
                  </div>
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.optYn1 === 'N'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의하지 않음</p>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="r-info after1">
            <div class="article diagNm">
              <h5 class="a-title" :class="{ warn: form.diagnosis.hasError }">
                진단명 또는 주증상
              </h5>
              <div class="a-content">
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="진단명을 입력해주세요."
                    v-model="form.diagnosis.value"
                    @keyup="checkDiagnosis()"
                    @blur="checkDiagnosis()"
                    maxlength="40"
                    @keyup.enter="focusControl()"
                  />
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title" :class="{ warn: form.code.hasError }">
                진단코드
              </h5>
              <div class="a-content">
                <div class="input-wrap">
                  <input
                    type="text"
                    placeholder="진단코드 입력"
                    v-model="form.code.value"
                    @keyup="checkCode()"
                    @blur="checkCode()"
                    maxlength="3"
                    @keyup.enter="focusControl()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="diag-detail">
            <div class="item">
              <p class="title" :class="{ warn: form.vital.hasError }">
                환자 상태 또는 의뢰 내용
                <span v-if="form.vital.hasError">{{
                  form.vital.errorTxt
                }}</span>
              </p>
              <div class="textarea-wrap">
                <textarea
                  v-model="form.vital.value"
                  @keyup="checkVital($event)"
                  @blur="checkVital($event)"
                  @keyup.enter="focusControl()"
                />
              </div>
              <div class="attachment-wrap" style="100%">
                <p class="title">파일명:</p>
                <div class="attachment">
                  <div
                    class="input-wrap"
                    @click="download()"
                    :class="{
                      active: this.form.attachment.filename !== null,
                    }"
                    style="height: 40px"
                  >
                    <span
                      class="file-name"
                      v-text="form.attachment.filename"
                    ></span>
                    <input
                      class="hidden file-upload"
                      type="file"
                      @click.stop
                      @change="onFileChange($event)"
                    />
                  </div>
                  <div class="a-btn-wrap" style="height: 60px; width: 240px">
                    <tr>
                      <td>
                        <button
                          type="button"
                          @click="attachmentFile()"
                          style="width: 100px"
                        >
                          파일첨부
                        </button>
                      </td>
                      <td>
                        <button
                          class="delete"
                          v-if="form.attachment.filename !== ''"
                          type="button"
                          @click="deleteFile()"
                          style="width: 100px"
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                    <tr
                      style="
                        font-size: 11px;
                        width: 100%;
                        position: relative;
                        top: 10px;
                      "
                    >
                      <td>문서, 사진, 텍스트등</td>
                      <td>파일을 첨부가능 합니다.</td>
                    </tr>
                  </div>
                </div>
              </div>
            </div>
            <div class="item" v-if="this.form.rcvYn.value === 'Y'">
              <p class="title">회신내용</p>
              <div class="textarea-wrap">
                <textarea v-model="form.rcvConts.value" readonly />
              </div>
              <div class="attachment-wrap">
                <p class="title">파일명:</p>
                <div class="attachment_rcv">
                  <div class="input-wrap">
                    <div
                      v-for="(data, index) in this.form.rcvAttachment"
                      v-bind:key="index"
                      @click="download_rcvFile(data)"
                      :class="{
                        active: data !== null,
                      }"
                    >
                      <div
                        style="border: 2px solid #146f83"
                        v-if="data.fileNm !== undefined"
                      >
                        <span class="file-name">
                          {{ data.fileNm }}
                        </span>
                        <img
                          style="
                            width: 535px;
                            height: 400px;
                            object-fit: contain;
                          "
                          :src="
                            'https://www.drrefer.net/api/pic/' + data.fileId
                          "
                          v-if="
                            data.fileNm.substring(
                              data.fileNm.lastIndexOf('.')
                            ) === '.png' ||
                            data.fileNm.substring(
                              data.fileNm.lastIndexOf('.')
                            ) === '.jpg'
                          "
                        />
                      </div>
                      <div
                        style="border: 2px solid #146f83"
                        v-if="data.name !== undefined"
                      >
                        <span class="file-name">
                          {{ data.name }}
                        </span>
                        <img
                          style="
                            width: 535px;
                            height: 400px;
                            object-fit: contain;
                          "
                          :src="data.url"
                          v-if="
                            data.name.substring(data.name.lastIndexOf('.')) ===
                              '.png' ||
                            data.name.substring(data.name.lastIndexOf('.')) ===
                              '.jpg'
                          "
                        />
                      </div>
                      <div style="height: 10px" />
                    </div>
                    <input
                      class="hidden file-upload"
                      type="file"
                      @click.stop
                      @change="onFileChange($event)"
                      multiple
                    />
                  </div>
                </div>
              </div>

              <!--<div class="attachment-wrap">
                <input
                  type="file"
                  id="files"
                  name="files"
                  @change="onFileChange($event)"
                  multiple
                /><br /><br />
                <div v-for="data in this.form.rcvAttachment">
                  <span class="file-name" v-if="data.fileNm !== ''">
                    {{ data.fileNm }}
                  </span>
                  <span class="file-name" v-if="data.name !== ''">
                    {{ data.name }}
                  </span>
                </div>
              </div>-->
            </div>
          </div>

          <div
            class="agree-info"
            style="border-bottom: 1px solid #000000"
            v-if="agreeResult.agreeYn !== 'Y'"
          >
            <div class="agree-info">
              <h4>진료정보제공 동의서</h4>
              <div class="agree-caption first">
                <p class="paragraph">
                  환자 진료 후, 진료 내용을 환자를 의뢰한 병원(진료의뢰서
                  발신병원)으로 회신하고자 하면, 환자가 진료정보제공에 동의해야
                  합니다. <br />
                  진료정보제공 절차는 환자가 가지고 온 '닥터리퍼 진료의뢰서'에
                  있는 QR 코드를 이용하시면 됩니다.<br />
                  환자(또는 보호자)가 스마트폰 카메라로 QR 코드를 스캔하면,
                  간단한 절차를 거쳐 진료정보제공에 동의할 수 있습니다. <br />
                  환자가 '닥터리퍼 진료의뢰서'를 가지고 오지 않은 경우에는 아래
                  '출력' 버튼을 클릭하여 진료의뢰서를 재출력할 수 있습니다.
                </p>
              </div>
            </div>
            <div class="sender-info">
              <div class="article">
                <h5 class="title">발신 병원</h5>
                <div class="a-content">
                  <div class="input-wrap readonly">
                    <span class="viewer" v-text="form.userData.hospNm"></span>
                  </div>
                </div>
              </div>
              <div class="article">
                <h5 class="title">의사명</h5>
                <div class="a-content">
                  <div class="input-wrap readonly">
                    <span class="viewer" v-text="form.userData.drNm"></span>
                  </div>
                </div>
              </div>
              <div class="article">
                <h5 class="title">의뢰일</h5>
                <div class="a-content">
                  <div class="input-wrap readonly">
                    <span class="viewer">{{ daySet(form.documentTime) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="document-btn-wrap"
        v-if="this.GET_REFER_ID === undefined || this.GET_REFER_ID === null"
      >
        <div class="item iconIn print" @click="hyperPdf()" v-if="isPdf">
          <img src="@/assets/images/menuIcon/print.svg" />
          <p>출력</p>
        </div>
        <div
          class="item iconIn introduce"
          v-if="
            form.receive.flag &&
            form.rcvDrId.value !== null &&
            form.rcvDrId.value !== undefined &&
            form.rcvDrId.value !== ''
          "
          @click="viewPr(form.rcvDrId.value)"
        >
          <img src="@/assets/images/menuIcon/infoDoc.svg" />
          <p>의사소개</p>
        </div>
        <div
          class="item iconIn"
          :class="{
            temporary: form.receive.flag,
          }"
          @click="temporarySave()"
        >
          <img src="@/assets/images/menuIcon/temporary.svg" />
          <p>임시저장</p>
        </div>
        <div
          class="item iconIn"
          :class="{
            submit:
              form.diagnosis.flag &&
              form.vital.flag &&
              form.receive.flag &&
              form.doctor.flag &&
              form.name.flag &&
              form.birth.flag &&
              form.gender.flag &&
              form.phone.flag,
          }"
          @click="next()"
        >
          <img src="@/assets/images/menuIcon/refer.svg" />
          <p>전송하기</p>
        </div>
      </div>

      <div class="document-btn-wrap" v-else>
        <div class="item iconIn print" @click="hyperPdf()" v-if="isPdf">
          <img src="@/assets/images/menuIcon/print.svg" />
          <p>출력</p>
        </div>
        <div
          class="item iconIn introduce"
          v-if="
            form.receive.flag &&
            form.rcvDrId.value !== null &&
            form.rcvDrId.value !== undefined &&
            form.rcvDrId.value !== ''
          "
          @click="viewPr(form.rcvDrId.value)"
        >
          <img src="@/assets/images/menuIcon/infoDoc.svg" />
          <p>의사소개</p>
        </div>
        <div
          class="item iconIn"
          :class="{
            submit: true,
          }"
          @click="modify('temporary')"
          v-if="form.completeYn === 'N'"
        >
          <img src="@/assets/images/menuIcon/temporary.svg" />
          <p>임시저장</p>
        </div>
        <div
          class="item iconIn"
          :class="{
            submit: true,
          }"
          @click="cancleRefer()"
          v-if="form.completeYn === 'N'"
          style="background-color: #ba55d3"
        >
          <img
            style="width: 35px; height: 35px"
            src="@/assets/images/icons/cross.png"
          />
          <div>
            <p>임시저장</p>
            <p>취소</p>
          </div>
        </div>
        <div
          class="item iconIn"
          :class="{
            submit:
              form.diagnosis.flag &&
              form.vital.flag &&
              form.receive.flag &&
              form.doctor.flag &&
              form.subject.flag &&
              form.code.flag &&
              form.name.flag &&
              form.birth.flag &&
              form.gender.flag &&
              form.phone.flag &&
              form.isCheckedAgreement.flag,
          }"
          @click="modify('completed')"
        >
          <img src="@/assets/images/menuIcon/update.svg" />
          <p v-html="modifyBtn"></p>
        </div>
      </div>
      <div class="prev-btn" @click="goList()">
        <div class="img-wrap">
          <img src="@/assets/images/prev-btn.svg" />
        </div>
        <p>뒤로가기</p>
      </div>
      <button id="focusControl"></button>
    </div>
    <div class="context" v-if="isMobile">
      <v-container class="mob-container">
        <v-row class="mob-container-row" justify="center">
          <v-col cols="12" class="text-center mob-subject">
            <h3 v-if="this.GET_REPLY_STATUS.reply === 'Y'">되의뢰서</h3>
            <h3 v-else>진료의뢰서</h3>
          </v-col>
          <v-col cols="12" v-if="this.form.completeYn === 'N'" class="mob-info">
            <p>* 진료 의뢰를 위해 아래 항목을 입력해야 됩니다.</p>
            <p>
              * 아래 항목을 입력하신 뒤 전송 버튼을 누르면, 진료 의뢰서가 수신
              병원 의사에게 전달됩니다.
            </p>
            <p>
              - 환자(또는 보호자)가 동의 절차를 완료해야 환자 정보가 온전하게
              전달이 가능합니다. (동의하지 않을 경우 가려진 상태로 전송됩니다.)
            </p>
            <p>
              - 환자(또는 보호자)가 종이로 출력된 진료 의뢰서 하단의 QR 코드를
              이용하여 동의 절차를 진행할 수 있도록 안내해주시기 바랍니다.
            </p>
            <p>
              (동의 내용: 예약 편의를 위한 휴대폰 번호 전송 및 진료 내용 회신
              동의 등)
            </p>
          </v-col>
          <v-col cols="12" v-else class="mob-info">
            <p>* 진료기록이 저장되어 있을경우 수정방법</p>
            <p>- 입력창의 내용을 원하는 내용으로 수정합니다.</p>
            <p>- 수정이 완료되면 하단의 '수정사항 저장하기'버튼을 클릭하여</p>
            <p>- 수정한 내용을 저장할 수 있습니다.</p>
            <p>- 수정된 진료의뢰서는 리스트에서 확인 할 수 있습니다.</p>
          </v-col>
          <v-col cols="12" class="request-title"> 수신병원 </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap choice"
              @click="callHospitalInfo()"
              v-if="form.completeYn !== 'Y'"
            >
              <span
                class="viewer"
                v-text="form.receive.value"
                v-if="form.receive.value"
              ></span>
              <span class="viewer no-selected" v-else
                >병원을 선택해주세요.</span
              >
            </div>
            <div class="input-wrap readonly" v-else>
              <span class="viewer" v-text="form.receive.value"></span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.subject.hasError }"
          >
            진료과
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap" v-if="form.completeYn !== 'Y'">
              <input
                type="text"
                placeholder="진료과목을 입력해주세요."
                v-model="form.subject.value"
                @keyup="checkSubject()"
                @blur="checkSubject()"
                maxlength="40"
              />
            </div>
            <div class="input-wrap readonly" v-else>
              <span class="viewer" v-text="form.subject.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 의사명 </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span
                  class="viewer viewr-value"
                  v-text="form.doctor.value"
                ></span>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.name.hasError }"
          >
            환자명
          </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap"
              :class="{ readonly: agreeResult.agreeYn === 'Y' }"
            >
              <input
                type="text"
                placeholder="환자명을 입력해주세요."
                v-model="form.name.value"
                @keyup="checkName()"
                @blur="checkName()"
                @keyup.enter="focusControl()"
                maxlength="40"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.birth.hasError || form.gender.hasError }"
          >
            주민등록번호
          </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap-birth"
              :class="{ readonly: agreeResult.agreeYn === 'Y' }"
            >
              <input
                type="text"
                placeholder="생년월일을 입력해주세요"
                v-model="form.birth.value"
                @keyup="checkBirth()"
                @blur="checkBirth()"
                maxlength="6"
                @keyup.enter="focusControl()"
              />
            </div>
            <div class="last">
              <div class="separated">-</div>
              <div
                class="input-wrap-birth-last"
                :class="{ readonly: agreeResult.agreeYn === 'Y' }"
              >
                <input
                  type="text"
                  v-model="form.gender.value"
                  @keyup="checkGender()"
                  @blur="checkGender()"
                  maxlength="1"
                  @keyup.enter="focusControl()"
                />
              </div>
              <span class="etc">XXXXXX</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{
              warn: form.phone.hasError,
            }"
          >
            연락처
          </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap request-call"
              :class="{ readonly: agreeResult.agreeYn === 'Y' }"
            >
              <input
                type="text"
                placeholder="환자(또는 보호자) 휴대폰 번호를 입력해 주세요."
                v-model="form.phone.value"
                @keyup="checkPhone()"
                @blur="checkPhone()"
                maxlength="13"
                @keyup.enter="focusControl()"
              />
            </div>
            <v-btn
              class="trans request-btn"
              dark
              color="#146F83"
              rounded
              @click="agreementTrans()"
              :class="{ isActive: isAgreeTransed }"
              elevation="0"
            >
              환자동의요청
              {{/*동의 누를 시 agreementTrans() => callAgreementRequestApi() */}}
            </v-btn>
            <v-btn
              class="confirm request-btn"
              dark
              color="#146F83"
              rounded
              @click="callAgreementCheckedApi()"
              v-if="isAgreeTransed"
              elevation="0"
            >
              환자동의확인
            </v-btn>
            <div class="trans-agree" v-if="isAgree === false">
              <p class="trans-agree-text" v-if="!isAgreeTransed">
                '환자동의요청' 버튼을 클릭하신 후, 환자분께 휴대폰으로 전송된
                URL을 클릭하여 동의 절차를 진행하도록 안내해 주세요.
              </p>
              <p v-else>
                환자의 휴대폰을 통해 동의요청을 진행해 주세요. 동의요청이 완료된
                후 환자동의확인 버튼을 클릭하면 환자의 이름과 전화번호가
                수신병원에 수신됩니다.
              </p>
              <p
                class="warn"
                v-if="form.isCheckedAgreement.hasError"
                v-text="form.isCheckedAgreement.errorTxt"
              ></p>
            </div>
            <div class="trans-agree" v-else>
              <p class="trans-agree-text">
                환자가 정보제공 동의에 동의하셨습니다. 예약상담 전화를 기다리고
                있을 수 있으니 전화상담을 부탁드립니다.
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            v-if="
              agreeResult.agreeYn === undefined || agreeResult.agreeYn === 'N'
            "
          >
            진료정보제공 동의 절차 안내
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="content-border">
              <p>
                ⊙ 진료 예약 편의 제공을 위해 환자(또는 보호자)의 휴대폰 번호를
                진료의뢰 수신병원에 전송하고,환자의 진료 내용(검사결과, 진단 및
                치료 내역)의 회신을 받기 위해서는 환자(또는 보호자)의 동의가
                필요합니다.
              </p>
              <p>
                ⊙ 휴대폰 번호 입력 후 '환자동의요청' (진료정보제공 동의) 버튼을
                클릭해야, 진료의뢰서가 서버로 전송됩니다.
              </p>
              <p>
                ⊙ 환자(또는 보호자)가 동의 절차를 완료하지 않으면, 수신병원
                의사는 환자 정보 일부만 볼 수 있습니다. 환자(또는 보호자)가 동의
                절차를 완료하면, 환자의 이름, 휴대폰 번호가 온전하게 전송됩니다.
              </p>
              <p>
                ⊙ 동의 절차를 지금 완료하지 않은 경우에는 종이로 출력된
                진료의뢰서 하단 QR 코드를 이용할 수 있습니다.
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.diagnosis.hasError }"
          >
            진단명 또는 주증상
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="진단명을 입력해주세요."
                v-model="form.diagnosis.value"
                @keyup="checkDiagnosis()"
                @blur="checkDiagnosis()"
                maxlength="40"
                @keyup.enter="focusControl()"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.code.hasError }"
          >
            진단코드
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="진단코드 입력"
                v-model="form.code.value"
                @keyup="checkCode()"
                @blur="checkCode()"
                maxlength="3"
                @keyup.enter="focusControl()"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            class="request-title"
            :class="{ warn: form.vital.hasError }"
          >
            환자 상태 또는 의뢰 내용
            <span v-if="form.vital.hasError">{{ form.vital.errorTxt }}</span>
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="textarea-wrap">
              <v-textarea
                v-model="form.vital.value"
                @keyup="checkVital($event)"
                @blur="checkVital($event)"
                @keyup.enter="focusControl()"
                outlined
                no-resize
              />
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 파일명 </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap request-file"
              @click="download()"
              :class="{
                active: this.form.attachment.filename !== null,
              }"
            >
              <span
                class="file-name"
                v-text="
                  form.attachment.filename
                    ? form.attachment.filename
                    : '첨부파일을 선택해주세요.'
                "
              ></span>
              <input
                class="hidden file-upload"
                type="file"
                hidden
                @click.stop
                @change="onFileChange($event)"
              />
            </div>
            <v-btn
              class="request-btn request-btn-file"
              dark
              color="#146F83"
              rounded
              @click="attachmentFile()"
              elevation="0"
            >
              파일첨부
            </v-btn>
            <v-btn
              class="delete request-btn request-btn-file"
              v-if="form.attachment.filename !== ''"
              dark
              color="red"
              rounded
              @click="deleteFile()"
              elevation="0"
            >
              삭제
            </v-btn>
            <div class="trans-agree" v-else>
              <p class="trans-agree-text">
                문서, 사진, 텍스트 등의 파일 첨부가 가능합니다.
              </p>
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 진료정보제공 동의서 </v-col>
          <v-col cols="12" class="request-content">
            <div class="content-border">
              <p>
                - 환자 진료 후, 진료 내용을 환자를 의뢰한 병원(진료의뢰서
                발신병원)으로 회신하고자 하면, 환자가 진료정보제공에 동의해야
                합니다.
              </p>
              <p>
                - 진료정보제공 절차는 환자가 가지고 온 '닥터리퍼 진료의뢰서'에
                있는 QR 코드를 이용하시면 됩니다.
              </p>
              <p>
                - 환자(또는 보호자)가 스마트폰 카메라로 QR 코드를 스캔하면,
                간단한 절차를 거쳐 진료정보제공에 동의할 수 있습니다.
              </p>
              <p>
                - 환자가 '닥터리퍼 진료의뢰서'를 가지고 오지 않은 경우에는 아래
                '출력' 버튼을 클릭하여 진료의뢰서를 재출력할 수 있습니다.
              </p>
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 발신 병원 </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer" v-text="form.userData.hospNm"></span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 의사명 </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer" v-text="form.userData.drNm"></span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="request-title"> 의뢰일 </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer">{{ daySet(form.documentTime) }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="request-content text-center">
            <div
              v-if="
                this.GET_REFER_ID === undefined || this.GET_REFER_ID === null
              "
              style="text-center"
            >
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                @click="hyperPdf()"
                v-if="isPdf"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/print.svg" />
                출력
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                elevation="0"
                v-if="
                  form.receive.flag &&
                  form.rcvDrId.value !== null &&
                  form.rcvDrId.value !== undefined &&
                  form.rcvDrId.value !== ''
                "
                @click="viewPr(form.rcvDrId.value)"
              >
                <img src="@/assets/images/menuIcon/infoDoc.svg" />
                의사소개
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                elevation="0"
                @click="modify('temporary')"
                v-if="form.completeYn === 'N'"
                :class="{
                  temporary: form.receive.flag,
                }"
              >
                <img src="@/assets/images/menuIcon/temporary.svg" />
                임시저장
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                elevation="0"
                @click="next()"
                :class="{
                  submit:
                    form.diagnosis.flag &&
                    form.vital.flag &&
                    form.receive.flag &&
                    form.doctor.flag &&
                    form.name.flag &&
                    form.birth.flag &&
                    form.gender.flag &&
                    form.phone.flag,
                }"
              >
                <img src="@/assets/images/menuIcon/refer.svg" />
                전송하기
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                elevation="0"
                @click="hyperPdf()"
                v-if="isPdf"
              >
                <img src="@/assets/images/menuIcon/print.svg" />
                출력
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                elevation="0"
                v-if="
                  form.receive.flag &&
                  form.rcvDrId.value !== null &&
                  form.rcvDrId.value !== undefined &&
                  form.rcvDrId.value !== ''
                "
                @click="viewPr(form.rcvDrId.value)"
              >
                <img src="@/assets/images/menuIcon/infoDoc.svg" />
                의사소개
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                elevation="0"
                :class="{
                  submit: true,
                }"
                @click="modify('temporary')"
                v-if="form.completeYn === 'N'"
              >
                <img src="@/assets/images/menuIcon/temporary.svg" />
                임시저장
              </v-btn>
              <v-btn
                class="request-btn request-btn-end-cancle"
                dark
                color="#BA55D3"
                rounded
                elevation="0"
                :class="{
                  submit: true,
                }"
                @click="cancleRefer()"
                v-if="form.completeYn === 'N'"
              >
                <img src="@/assets/images/icons/cross.png" />
                임시저장 취소
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                elevation="0"
                :class="{
                  submit:
                    form.diagnosis.flag &&
                    form.vital.flag &&
                    form.receive.flag &&
                    form.doctor.flag &&
                    form.subject.flag &&
                    form.code.flag &&
                    form.name.flag &&
                    form.birth.flag &&
                    form.gender.flag &&
                    form.phone.flag &&
                    form.isCheckedAgreement.flag,
                }"
                @click="modify('completed')"
              >
                <img src="@/assets/images/menuIcon/update.svg" />
                <span v-html="modifyBtn"></span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="request-content text-center">
            <v-btn
              class="request-btn-end-circle"
              color="white"
              fab
              @click="goList()"
            >
              <img class="back" src="@/assets/images/prev-btn.svg" />
            </v-btn>
            <span class="back-text" @click="goList()"> 뒤로가기 </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <search-hospital-popup-reform
      v-if="checkSearchPopupReform"
      :popupSetSearch="popupSetSearch"
      @receive-doctor="receiveDoctor"
      @reload-nav="reloadNav"
    />
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @callFunction="callAgreementRequestApi"
    />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";
import SearchHospitalPopupReform from "../../components/modal/SearchHospitalPopup_reform.vue";
export default {
  components: {
    DefaultPopup,
    SearchHospitalPopupReform,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      hidedPhone: false,
      form: {
        userData: {
          drId: "",
          drNm: "",
          hospNm: "",
        },
        diagnosis: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단명을 입력해주세요.",
        },
        vital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*환자상태를 입력해주세요.",
        },
        receive: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*수신병원을 입력해주세요.",
        },
        doctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료의사를 입력해주세요.",
        },
        subject: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료과목을 입력해주세요.",
        },
        code: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단코드를 입력해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "환자명을 입력해주세요.",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "생년월일을 입력해주세요.",
        },
        gender: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "성별을 선택해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*8~20자, 영문+숫자 조합으로 입력해주세요",
        },
        attachment: {
          flag: false,
          title: "병원 로고",
          source: "",
          url: "",
          filename: "",
          isDeleted: 0,
        },
        isPressAgreementRequest: {
          flag: false,
          hasError: false,
          errorTxt: "*동의요청을 확인해주세요.",
        },
        isCheckedAgreement: {
          flag: false,
          hasError: false,
          errorTxt: "*동의여부를 확인해주세요.",
        },
        agreeId: {
          value: "",
        },
        rcvDrId: {
          value: "",
        },
        rcvConts: {
          value: "",
        },
        referId: {
          value: "",
        },
        visitYn: {
          value: "",
        },
        rcvYn: {
          value: "",
        },
        documentTime: "",
        completeYn: "N",
        rcvHospNo: "",
      },
      isAgreeTransed: false,
      agreeResult: [],
      popupSet: {},
      popupSetSearch: {},
      showNav: true,
      isPdf: false,
      setCount: false,
      modifyBtn: "전송하기",
      prevRoute: null,
      sendCount: 0,
      isMobile: false,
      isAgree: false,
    };
  },
  created() {
    function Mobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }

    if (Mobile()) {
      // 모바일일 경우
      this.isMobile = true;
    } else {
      // 모바일 외
      this.isMobile = false;
    }

    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
    /*console.log("########################################");
    console.log(location.href);
    console.log("########################################");*/
    this.configureType();
    this.configTest();
  },
  mounted() {
    /*console.log("=============임시 ================");
    console.log(this.agreeResult);*/
  },
  computed: {
    // store에서 원하는 변수, 함수 획득
    ...mapState("basic", ["checkPopup", "isLoading", "checkSearchPopupReform"]),
    ...mapGetters("request", [
      "GET_AGREE_RESULT",
      "GET_SMS_LIST",
      "GET_REFER_ID",
      "GET_REPLY_STATUS",
      "GET_AGREE_ID",
    ]),
    ...mapGetters("referList", ["GET_REFER_DETAIL"]),
    ...mapGetters("promotion", ["GET_DOCTOR_INFO"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
    daySet() {
      return (day) => {
        //console.log("day", day);
        if (day !== undefined) {
          let dano = "";
          dano += day.substr(0, 4);
          dano += "/";
          dano += day.substr(4, 2);
          dano += "/";
          dano += day.substr(6, 2);
          return dano;
        }
      };
    },
    genetateHyphen() {
      return (numData, isBirth) => {
        //console.log("가져온 번호들", numData);
        //console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_SEARCH_POPUP_REFORM"]),
    ...mapMutations("request", ["SET_REFER_ID"]),
    ...mapActions("util", ["BRING_PDF_URL_ON_REFER", "BRING_PDF"]),
    ...mapActions("request", [
      "BRING_AGREE_RESULT",
      "REFER_SUBMIT",
      "REFER_MODIFY",
      "BRING_SMS_CODE",
      "REFER_REPLY",
      "BRING_AGREE_ID",
      "CANCLE_REFER",
    ]),
    ...mapMutations("referList", ["SET_REFER_DETAIL"]),
    ...mapActions("referList", ["REFER_DETAIL"]),
    ...mapActions("join", ["FETCH_BASIC"]),
    configTest() {
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!");
      //console.log(window.localStorage);
      //console.log(window.localStorage.getItem("search_doctor"));

      const receiveData = JSON.parse(
        window.localStorage.getItem("search_doctor")
      );

      this.receiveDoctor(receiveData);
    },
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    async cancleRefer() {
      try {
        const referParam = {
          referId: this.GET_REFER_DETAIL.param.referId,
        };
        //console.log("referParam", referParam);

        if (referParam.referId === null || referParam.referId === undefined)
          referParam.referId = "";

        var referIdBoo = /^[0-9]{8,14}$/;

        if (!referIdBoo.test(referParam.referId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        await this.CANCLE_REFER(referParam).then(() => {
          this.SET_POPUP(true);
          this.popupSet.title = "진료의뢰서 임시저장 취소";
          this.popupSet.content = "진료의뢰서 임시저장을 취소하였습니다.";
          this.popupSet.popType = "referCancle";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/v/mypage/request/0";
          this.popupSet.destination = true;
        });
        /**
         * request After 에 필요한 내용 기입하기
         */
      } catch (error) {
        //console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    goList() {
      //this.$router.push("/v/request/list");
      //console.log(this.$router);
      //this.$router.go(-1);
      // if (
      //   this.prevRoute.path === "/v/pr/manage" ||
      //   this.prevRoute.path === "/v/modify/member" ||
      //   this.prevRoute.path === "/v/modify/search" ||
      //   this.prevRoute.path === "/v/modify/sub"
      // ) {
      //   //console.log("22222222222222222222222222222222222222222");
      //   window.history.go(-3);
      // } else {
      //   if (this.prevRoute.path !== null) {
      //     //console.log(this.prevRoute.path);
      //     //console.log("11111111111111111111111111111111111111");
      //     this.$router.push(this.prevRoute.path).catch(()=>{});
      //   }
      // }
      this.$router.go(-1);
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let monthValue;
      let today;
      if (String(month).length < 2) {
        monthValue = String(year) + "0" + String(month);
      } else {
        monthValue = String(year) + "" + String(month);
      }
      const material = monthValue + "";
      const material2 = monthValue + "0";

      if (String(day).length < 2) {
        today = String(material2 + day);
      } else {
        today = String(material + day);
      }
      this.form.documentTime = today;
    },
    phoneFomatter(number) {
      //console.log("정보", number);
      //console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/v/mypage/request/0").catch(() => {});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
    checkGender() {
      this.form.gender.flag = false;
      this.form.gender.hasError = true;
      const Gender = this.form.gender.value.replace(/\s/gi, "");
      if (Gender === "") {
        this.form.gender.flag = false;
        this.form.gender.errorTxt = "*주민등록번호를 입력해주세요.";
        return false;
      }
      const isGender = /^[0-9]{1}$/;
      if (!isGender.test(Gender)) {
        this.form.gender.errorTxt = "*-을 제외하고 숫자만 입력해주세요.";
        this.form.gender.flag = false;
        this.form.gender.hasError = true;
        return false;
      }
      this.form.gender.flag = true;
      this.form.gender.hasError = false;
      this.form.gender.errorTxt = "";
      return true;
    },
    viewPr(drId) {
      /**
      this.SET_DRID_PARAM(drId);
      this.$router.push("/v/pr/view");
       */

      let routeData = this.$router.resolve({
        name: "PrView",
        params: { code: drId },
      });
      window.open(routeData.href, "_blank");
    },
    /** 수정폼의 메서드 */
    checkTempoFlag() {
      if (!this.form.receive.flag) {
        this.SET_POPUP(true); //DefaultPopup 에서 SET_POPUP
        this.popupSet.title = "수신병원 확인";
        this.popupSet.content = "수신병원이 입력되지 않았습니다. 확인해주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인"; //SET_POPUP config
        if (!this.form.receive.flag) {
          this.form.receive.hasError = true;
        }
        return false;
      }
      return true;
    },
    async modify(condition) {
      if (condition === "completed") {
        if (this.form.visitYn.value !== "Y") {
          if (this.checkFlag()) {
            const referParam = {
              rcvDrId: this.form.rcvDrId.value,
              sndDrId: this.form.userData.drId,
              sndDrNm: this.form.userData.drNm,
              sndHospNm: this.form.userData.hospNm,
              rcvHospNm: this.form.receive.value,
              rcvDrNm: this.form.doctor.value,
              diagCd: this.form.code.value,
              rcvParts: this.form.subject.value,
              patNm: this.form.name.value,
              patTelno: this.form.phone.value.replace(/-/gi, ""),
              diagNm: this.form.diagnosis.value,
              referConts: this.form.vital.value,
              agreeId: this.form.agreeId.value,
              completeYn: "Y",
              referId: this.form.referId.value,
              isDeletedAttchId: 0,
            };

            if (this.form.birth.value !== "" && this.form.gender.value !== "") {
              referParam.patBirth =
                this.form.birth.value + "-" + this.form.gender.value;
            }
            //console.log("referParam : ", referParam);
            if (
              this.GET_REFER_DETAIL.param.referAttchId !== "" &&
              this.GET_REFER_DETAIL.param.referAttchId !== null
            ) {
              referParam.referAttchId =
                this.GET_REFER_DETAIL.param.referAttchId;
              referParam.isDeletedAttchId = this.form.attachment.isDeleted;
            }

            // 유효성 검사
            // 1. 의뢰진단번호
            if (referParam.referId === null || referParam.referId === undefined)
              referParam.referId = "";

            var referIdBoo = /^[0-9]{8,14}$/;

            if (!referIdBoo.test(referParam.referId)) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 2. 수신 아이디
            if (referParam.rcvDrId === null || referParam.rcvDrId === undefined)
              referParam.rcvDrId = "";

            var rcvDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

            if (!rcvDrIdBoo.test(referParam.rcvDrId)) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 3. 수신자명
            if (referParam.rcvDrNm === null || referParam.rcvDrNm === undefined)
              referParam.rcvDrNm = "";

            var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

            if (!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi, ""))) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 4. 진료과목
            if (
              referParam.rcvParts === null ||
              referParam.rcvParts === undefined
            )
              referParam.rcvParts = "";

            if (
              referParam.rcvParts.length < 1 ||
              referParam.rcvParts.length > 100
            ) {
              this.SET_POPUP(true);
              this.popupSet.title = "진료과목 확인";
              this.popupSet.content =
                "100자 이내의 진료과목을 입력해주시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // // 5. 동의번호
            // if(referParam.agreeId === null
            // || referParam.agreeId === undefined)
            // referParam.agreeId = "";

            // var agreeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

            // if(!agreeIdBoo.test(referParam.agreeId)){
            //   this.SET_POPUP(true);
            //   this.popupSet.title = "서버요청 실패";
            //   this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            //   this.popupSet.popType = "warn";
            //   this.popupSet.cancelBtnText = "확인";
            //   return;
            // }

            // 6. 환자명
            if (referParam.patNm === null || referParam.patNm === undefined)
              referParam.patNm = "";

            var patNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{2,20}$/;

            if (!patNmBoo.test(referParam.patNm.replace(/\s/gi, ""))) {
              this.SET_POPUP(true);
              this.popupSet.title = "환자명 확인";
              this.popupSet.content =
                "환자명의 글자 수 및 특수문자를 확인하시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 7. 환자생년월일
            if (
              referParam.patBirth === null ||
              referParam.patBirth === undefined
            )
              referParam.patBirth = "";

            var patBirthBoo = /^[0-9]{0,8}$/;

            if (!patBirthBoo.test(referParam.patBirth.replace(/-/gi, ""))) {
              this.SET_POPUP(true);
              this.popupSet.title = "환자생년월일 확인";
              this.popupSet.content =
                "환자생년월일의 글자 수 및 특수문자를 확인하시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 8. 환자연락처
            if (
              referParam.patTelno === null ||
              referParam.patTelno === undefined
            )
              referParam.patTelno = "";

            var patTelnoBoo = /^[0-9]{8,14}$/;

            if (!patTelnoBoo.test(referParam.patTelno.replace(/-/gi, ""))) {
              this.SET_POPUP(true);
              this.popupSet.title = "환자연락처 확인";
              this.popupSet.content = "환자연락처를 확인하시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 9. 발신아이디
            if (referParam.sndDrId === null || referParam.sndDrId === undefined)
              referParam.sndDrId = "";

            var sndDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

            if (!sndDrIdBoo.test(referParam.sndDrId)) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // // 10. 진단코드
            //  if(referParam.diagCd === null
            // || referParam.diagCd === undefined)
            // referParam.diagCd = "";

            // var diagCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

            // if(!diagCdBoo.test(referParam.diagCd)){
            //   this.SET_POPUP(true);
            //   this.popupSet.title = "서버요청 실패";
            //   this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            //   this.popupSet.popType = "warn";
            //   this.popupSet.cancelBtnText = "확인";
            //   return;
            // }

            // // 11. 진단명
            //  if(referParam.diagNm === null
            // || referParam.diagNm === undefined)
            // referParam.diagNm = "";

            // if(referParam.diagNm.length < 0
            // || referParam.diagNm.length > 200){
            //   this.SET_POPUP(true);
            //   this.popupSet.title = "진단명 확인";
            //   this.popupSet.content = "진단명을 200자 이내로 입력해주세요.";
            //   this.popupSet.popType = "warn";
            //   this.popupSet.cancelBtnText = "확인";
            //   return;
            // }

            // 12. 환자상태/의뢰내용
            if (
              referParam.referConts === null ||
              referParam.referConts === undefined
            )
              referParam.referConts = "";

            if (
              referParam.referConts.length < 0 ||
              referParam.referConts.length > 3000
            ) {
              this.SET_POPUP(true);
              this.popupSet.title = "의뢰첨부파일번호 확인";
              this.popupSet.content =
                "환자상태/의뢰내용을 3000자 이내로 입력해주세요.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 13. 의뢰첨부파일번호
            if (
              referParam.referAttchId === null ||
              referParam.referAttchId === undefined
            )
              referParam.referAttchId = "";

            if (
              referParam.referAttchId.length < 0 ||
              referParam.referAttchId.length > 40
            ) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            // 14. completeYn
            if (
              referParam.completeYn === null ||
              referParam.completeYn === undefined
            )
              referParam.completeYn = "";

            var completeYnBoo = /^[A-Z]{0,1}$/;

            if (!completeYnBoo.test(referParam.completeYn)) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            const transedReferParam = JSON.stringify(referParam);
            const transData = new FormData();
            transData.append("referParam", transedReferParam);
            transData.append(
              "referFile",
              this.form.attachment.source !== "" &&
                this.form.attachment.source !== null
                ? this.form.attachment.source
                : this.GET_REFER_DETAIL.param.referAttchId
            );
            try {
              await this.REFER_MODIFY(transData).then((data) => {
                this.SET_POPUP(true);
                this.popupSet.title = "진료의뢰서 등록완료";
                this.popupSet.content =
                  "진료의뢰서를 성공적으로 등록하였습니다.";
                this.popupSet.popType = "refer";
                this.popupSet.pdfParam = data.referId;
                this.popupSet.confirmBtnText = "확인";
                this.popupSet.nextLink = "/v/mypage/request/0";
                this.popupSet.destination = true;
              });
              /**
               * request After 에 필요한 내용 기입하기
               */
            } catch (error) {
              console.log("error: ", error);
              if (error.data.status !== 5000 && error.data.status !== 5002) {
                this.SET_POPUP(true);
                this.popupSet.title = "서버요청 실패";
                this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
                this.popupSet.popType = "warn";
                this.popupSet.cancelBtnText = "확인";
              }
            }
          }
        }
      } else {
        /** 리퍼파람 있는지 없는지에 대한 체크가 필요함 (초기 버튼 플래그도 바꿔야함) */
        const referParam = {
          rcvDrId: this.form.rcvDrId.value,
          sndDrId: this.form.userData.drId,
          sndDrNm: this.form.userData.drNm,
          sndHospNm: this.form.userData.hospNm,
          rcvHospNm: this.form.receive.value,
          rcvDrNm: this.form.doctor.value,
          diagCd: this.form.code.value,
          rcvParts: this.form.subject.value,
          patNm: this.form.name.value,
          patTelno: this.form.phone.value.replace(/-/gi, ""),
          diagNm: this.form.diagnosis.value,
          referConts: this.form.vital.value,
          agreeId: this.form.agreeId.value,
          referId: this.form.referId.value,
          completeYn: this.form.completeYn,
          isDeletedAttchId: 0,
        };
        if (this.form.birth.value !== "" && this.form.gender.value !== "") {
          referParam.patBirth =
            this.form.birth.value + "-" + this.form.gender.value;
        }
        //console.log("referParam", referParam);
        if (
          this.GET_REFER_DETAIL.param.referAttchId !== "" &&
          this.GET_REFER_DETAIL.param.referAttchId !== null
        ) {
          referParam.referAttchId = this.GET_REFER_DETAIL.param.referAttchId;
          referParam.isDeletedAttchId = this.form.attachment.isDeleted;
        }

        // 유효성 검사
        // 1. 의뢰진단번호
        if (referParam.referId === null || referParam.referId === undefined)
          referParam.referId = "";

        var referIdBoo = /^[0-9]{0,14}$/;

        if (!referIdBoo.test(referParam.referId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. 수신 아이디
        if (referParam.rcvDrId === null || referParam.rcvDrId === undefined)
          referParam.rcvDrId = "";

        var rcvDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{0,20}$/;

        if (!rcvDrIdBoo.test(referParam.rcvDrId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 수신자명
        if (referParam.rcvDrNm === null || referParam.rcvDrNm === undefined)
          referParam.rcvDrNm = "";

        var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

        if (!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. 진료과목
        if (referParam.rcvParts === null || referParam.rcvParts === undefined)
          referParam.rcvParts = "";

        if (
          referParam.rcvParts.length < 0 ||
          referParam.rcvParts.length > 100
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "진료과목 확인";
          this.popupSet.content =
            "100자 이내의 진료과목을 입력해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 5. 동의번호
        if (referParam.agreeId === null || referParam.agreeId === undefined)
          referParam.agreeId = "";

        var agreeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,14}$/;

        if (!agreeIdBoo.test(referParam.agreeId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 6. 환자명
        if (referParam.patNm === null || referParam.patNm === undefined)
          referParam.patNm = "";

        var patNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

        if (!patNmBoo.test(referParam.patNm.replace(/\s/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "환자명 확인";
          this.popupSet.content =
            "환자명의 글자 수 및 특수문자를 확인하시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 7. 환자생년월일
        if (referParam.patBirth === null || referParam.patBirth === undefined)
          referParam.patBirth = "";

        var patBirthBoo = /^[0-9]{0,8}$/;

        if (!patBirthBoo.test(referParam.patBirth.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "환자생년월일 확인";
          this.popupSet.content =
            "환자생년월일의 글자 수 및 특수문자를 확인하시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 8. 환자연락처
        if (referParam.patTelno === null || referParam.patTelno === undefined)
          referParam.patTelno = "";

        var patTelnoBoo = /^[0-9]{0,14}$/;

        if (!patTelnoBoo.test(referParam.patTelno.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "환자연락처 확인";
          this.popupSet.content = "환자연락처를 확인하시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 발신아이디
        if (referParam.sndDrId === null || referParam.sndDrId === undefined)
          referParam.sndDrId = "";

        var sndDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{0,20}$/;

        if (!sndDrIdBoo.test(referParam.sndDrId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 10. 진단코드
        if (referParam.diagCd === null || referParam.diagCd === undefined)
          referParam.diagCd = "";

        var diagCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

        if (!diagCdBoo.test(referParam.diagCd)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 11. 진단명
        if (referParam.diagNm === null || referParam.diagNm === undefined)
          referParam.diagNm = "";

        if (referParam.diagNm.length < 0 || referParam.diagNm.length > 200) {
          this.SET_POPUP(true);
          this.popupSet.title = "진단명 확인";
          this.popupSet.content = "진단명을 200자 이내로 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 12. 환자상태/의뢰내용
        if (
          referParam.referConts === null ||
          referParam.referConts === undefined
        )
          referParam.referConts = "";

        if (
          referParam.referConts.length < 0 ||
          referParam.referConts.length > 3000
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "의뢰첨부파일번호 확인";
          this.popupSet.content =
            "환자상태/의뢰내용을 3000자 이내로 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 13. 의뢰첨부파일번호
        if (
          referParam.referAttchId === null ||
          referParam.referAttchId === undefined
        )
          referParam.referAttchId = "";

        if (
          referParam.referAttchId.length < 0 ||
          referParam.referAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 14. completeYn
        if (
          referParam.completeYn === null ||
          referParam.completeYn === undefined
        )
          referParam.completeYn = "";

        var completeYnBoo = /^[A-Z]{0,1}$/;

        if (!completeYnBoo.test(referParam.completeYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        const transedReferParam = JSON.stringify(referParam);
        const transData = new FormData();
        transData.append("referParam", transedReferParam);
        transData.append(
          "referFile",
          this.form.attachment.source !== "" &&
            this.form.attachment.source !== null
            ? this.form.attachment.source
            : this.GET_REFER_DETAIL.param.referAttchId
        );
        try {
          await this.REFER_MODIFY(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "임시저장완료";
            this.popupSet.content = "진료의뢰서를 임시저장하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          });
          /**
           * request After 에 필요한 내용 기입하기
           */
        } catch (error) {
          console.log("error: ", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
    async temporarySave() {
      if (this.checkTempoFlag()) {
        const referParam = {
          rcvDrId: this.form.rcvDrId.value,
          sndDrId: this.form.userData.drId,
          sndDrNm: this.form.userData.drNm,
          sndHospNm: this.form.userData.hospNm,
          rcvHospNm: this.form.receive.value,
          rcvDrNm: this.form.doctor.value,
          diagCd: this.form.code.value,
          rcvParts: this.form.subject.value,
          patNm: this.form.name.value,
          patTelno: this.form.phone.value.replace(/-/gi, ""),
          diagNm: this.form.diagnosis.value,
          referConts: this.form.vital.value,
          agreeId: this.form.agreeId.value,
          completeYn: this.form.completeYn,
          isDeletedAttchId: 0,
        };
        if (this.form.birth.value !== "" && this.form.gender.value !== "") {
          referParam.patBirth =
            this.form.birth.value + "-" + this.form.gender.value;
        }
        if (this.form.referId.value === "") {
          //console.log("referParam", referParam);
          const transedReferParam = JSON.stringify(referParam);
          const transData = new FormData();
          transData.append("referParam", transedReferParam);
          if (
            this.form.attachment.source !== "" &&
            this.form.attachment.source !== null
          ) {
            transData.append("referFile", this.form.attachment.source);
          }
          //console.log("referParam", referParam);
          // 유효성 검사
          // 1. 의뢰진단번호
          if (referParam.referId === null || referParam.referId === undefined)
            referParam.referId = "";

          var referIdBoo = /^[0-9]{0,14}$/;

          if (!referIdBoo.test(referParam.referId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 수신 아이디
          if (referParam.rcvDrId === null || referParam.rcvDrId === undefined)
            referParam.rcvDrId = "";

          var rcvDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{0,20}$/;

          if (!rcvDrIdBoo.test(referParam.rcvDrId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 3. 수신자명
          if (referParam.rcvDrNm === null || referParam.rcvDrNm === undefined)
            referParam.rcvDrNm = "";

          var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

          if (!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 4. 진료과목
          if (referParam.rcvParts === null || referParam.rcvParts === undefined)
            referParam.rcvParts = "";

          if (
            referParam.rcvParts.length < 0 ||
            referParam.rcvParts.length > 100
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "진료과목 확인";
            this.popupSet.content =
              "100자 이내의 진료과목을 입력해주시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 5. 동의번호
          if (referParam.agreeId === null || referParam.agreeId === undefined)
            referParam.agreeId = "";

          var agreeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,14}$/;

          if (!agreeIdBoo.test(referParam.agreeId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 6. 환자명
          if (referParam.patNm === null || referParam.patNm === undefined)
            referParam.patNm = "";

          var patNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

          if (!patNmBoo.test(referParam.patNm.replace(/\s/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자명 확인";
            this.popupSet.content =
              "환자명의 글자 수 및 특수문자를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 7. 환자생년월일
          if (referParam.patBirth === null || referParam.patBirth === undefined)
            referParam.patBirth = "";

          var patBirthBoo = /^[0-9]{0,8}$/;

          if (!patBirthBoo.test(referParam.patBirth.replace(/-/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자생년월일 확인";
            this.popupSet.content =
              "환자생년월일의 글자 수 및 특수문자를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 8. 환자연락처
          if (referParam.patTelno === null || referParam.patTelno === undefined)
            referParam.patTelno = "";

          var patTelnoBoo = /^[0-9]{0,14}$/;

          if (!patTelnoBoo.test(referParam.patTelno.replace(/-/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자연락처 확인";
            this.popupSet.content = "환자연락처를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 9. 발신아이디
          if (referParam.sndDrId === null || referParam.sndDrId === undefined)
            referParam.sndDrId = "";

          var sndDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{0,20}$/;

          if (!sndDrIdBoo.test(referParam.sndDrId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 10. 진단코드
          if (referParam.diagCd === null || referParam.diagCd === undefined)
            referParam.diagCd = "";

          var diagCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

          if (!diagCdBoo.test(referParam.diagCd)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 11. 진단명
          if (referParam.diagNm === null || referParam.diagNm === undefined)
            referParam.diagNm = "";

          if (referParam.diagNm.length < 0 || referParam.diagNm.length > 200) {
            this.SET_POPUP(true);
            this.popupSet.title = "진단명 확인";
            this.popupSet.content = "진단명을 200자 이내로 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 12. 환자상태/의뢰내용
          if (
            referParam.referConts === null ||
            referParam.referConts === undefined
          )
            referParam.referConts = "";

          if (
            referParam.referConts.length < 0 ||
            referParam.referConts.length > 3000
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "의뢰첨부파일번호 확인";
            this.popupSet.content =
              "환자상태/의뢰내용을 3000자 이내로 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 13. 의뢰첨부파일번호
          if (
            referParam.referAttchId === null ||
            referParam.referAttchId === undefined
          )
            referParam.referAttchId = "";

          if (
            referParam.referAttchId.length < 0 ||
            referParam.referAttchId.length > 40
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 14. completeYn
          if (
            referParam.completeYn === null ||
            referParam.completeYn === undefined
          )
            referParam.completeYn = "";

          var completeYnBoo = /^[A-Z]{0,1}$/;

          if (!completeYnBoo.test(referParam.completeYn)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }
          try {
            await this.REFER_SUBMIT(transData).then((data) => {
              //console.log("data");
              this.form.referId.value = data.referId;
              this.SET_POPUP(true);
              this.popupSet.title = "임시저장완료";
              this.popupSet.content = "진료의뢰서를 임시저장하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            });
            /**
             * request After 에 필요한 내용 기입하기
             */
          } catch (error) {
            console.log("error: ", error);
            if (error.data.status !== 5000 && error.data.status !== 5002) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 임시저장을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            }
          }
        } else {
          referParam.referId = this.form.referId.value;
          //console.log("referParam", referParam);
          const transedReferParam = JSON.stringify(referParam);
          const transData = new FormData();
          transData.append("referParam", transedReferParam);
          if (
            this.form.attachment.source !== "" &&
            this.form.attachment.source !== null
          ) {
            transData.append("referFile", this.form.attachment.source);
          }
          try {
            await this.REFER_MODIFY(transData).then(() => {
              this.SET_POPUP(true);
              this.popupSet.title = "임시저장완료";
              this.popupSet.content = "진료의뢰서를 임시저장하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            });
            /**
             * request After 에 필요한 내용 기입하기
             */
          } catch (error) {
            console.log("error: ", error);
            if (error.data.status !== 5000 && error.data.status !== 5002) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "진료의뢰서 임시저장을 실패하였습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            }
          }
        }
      } else {
        //console.log("체크 상태확인");
      }
    },
    download() {
      try {
        if (this.GET_REFER_DETAIL.param.referAttchId === undefined) {
          return;
        }
        if (
          this.form.attachment.filename !== null &&
          this.form.attachment.filename !== ""
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" +
              this.GET_REFER_DETAIL.param.referAttchId,
            "_blank"
          );
        }
      } catch {}
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      try {
        await this.BRING_PDF(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          //console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          //console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      try {
        await this.BRING_PDF_URL_ON_REFER(this.form.referId.value).then(
          () => {}
        );
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "PDF를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async bringDetail() {
      try {
        await this.REFER_DETAIL(this.GET_REFER_ID).then(() => {
          //console.log("bring data");
          //console.log(this.GET_REFER_DETAIL);

          this.form.rcvAttachment = this.GET_REFER_DETAIL.list;
          if (
            this.GET_REFER_DETAIL.param.rcvYn !== null &&
            this.GET_REFER_DETAIL.param.rcvYn !== ""
          ) {
            this.form.rcvYn.value = this.GET_REFER_DETAIL.param.rcvYn;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvDrId !== null &&
            this.GET_REFER_DETAIL.param.rcvDrId !== ""
          ) {
            this.form.rcvDrId.value = this.GET_REFER_DETAIL.param.rcvDrId;
          }
          if (
            this.GET_REFER_DETAIL.param.sndDrId !== null &&
            this.GET_REFER_DETAIL.param.sndDrId !== ""
          ) {
            this.form.userData.drId = this.GET_REFER_DETAIL.param.sndDrId;
          }
          if (
            this.GET_REFER_DETAIL.param.sndDrNm !== null &&
            this.GET_REFER_DETAIL.param.sndDrNm !== ""
          ) {
            this.form.userData.drNm = this.GET_REFER_DETAIL.param.sndDrNm;
          }
          if (
            this.GET_REFER_DETAIL.param.sndHospNm !== null &&
            this.GET_REFER_DETAIL.param.sndHospNm !== ""
          ) {
            this.form.userData.hospNm = this.GET_REFER_DETAIL.param.sndHospNm;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvHospNm !== null &&
            this.GET_REFER_DETAIL.param.rcvHospNm !== ""
          ) {
            this.form.receive.flag = true;
            this.form.receive.value = this.GET_REFER_DETAIL.param.rcvHospNm;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvDrNm !== null &&
            this.GET_REFER_DETAIL.param.rcvDrNm !== ""
          ) {
            this.form.doctor.flag = true;
            this.form.doctor.value = this.GET_REFER_DETAIL.param.rcvDrNm;
          }
          if (
            this.GET_REFER_DETAIL.param.diagCd !== null &&
            this.GET_REFER_DETAIL.param.diagCd !== ""
          ) {
            this.form.code.flag = true;
            this.form.code.value = this.GET_REFER_DETAIL.param.diagCd;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvParts !== null &&
            this.GET_REFER_DETAIL.param.rcvParts !== ""
          ) {
            this.form.subject.flag = true;
            this.form.subject.value = this.GET_REFER_DETAIL.param.rcvParts;
          }
          if (
            this.GET_REFER_DETAIL.param.patNm !== null &&
            this.GET_REFER_DETAIL.param.patNm !== ""
          ) {
            this.form.name.flag = true;
            this.form.name.value = this.GET_REFER_DETAIL.param.patNm;
          }
          if (
            this.GET_REFER_DETAIL.param.referDt !== null &&
            this.GET_REFER_DETAIL.param.referDt !== ""
          ) {
            this.form.documentTime = this.GET_REFER_DETAIL.param.referDt;
          }
          if (
            this.GET_REFER_DETAIL.param.patBirth !== null &&
            this.GET_REFER_DETAIL.param.patBirth !== ""
          ) {
            this.form.birth.flag = true;
            this.form.birth.value = this.GET_REFER_DETAIL.param.patBirth.substr(
              0,
              6
            );
            this.form.gender.flag = true;
            this.form.gender.value =
              this.GET_REFER_DETAIL.param.patBirth.split("-")[1];
          }
          if (
            this.GET_REFER_DETAIL.param.agreeYn !== null &&
            this.GET_REFER_DETAIL.param.agreeYn !== ""
          ) {
            const agreeData = {
              agreeYn: this.GET_REFER_DETAIL.param.agreeYn,
              diliNm: this.GET_REFER_DETAIL.param.diliNm,
              diliBirth: this.GET_REFER_DETAIL.param.diliBirth,
              rltnTypeNm: this.GET_REFER_DETAIL.param.rltnTypeNm,
              optYn1: this.GET_REFER_DETAIL.param.optYn1,
              optYn2: this.GET_REFER_DETAIL.param.optYn2,
              optYn3: this.GET_REFER_DETAIL.param.optYn3,
            };
            this.agreeResult = agreeData;
            //console.log("===============================");
            //console.log(this.agreeResult);
            if (agreeData.optYn1 === "N") {
              this.hidedPhone = true;
            }
            this.form.isCheckedAgreement.flag = true;
          } else {
            //console.log("===============================");
            //console.log("there has no agreeParam");
          }
          if (
            this.GET_REFER_DETAIL.param.patTelno !== null &&
            this.GET_REFER_DETAIL.param.patTelno !== ""
          ) {
            this.form.phone.flag = true;
            this.form.phone.value = this.phoneFomatter(
              this.GET_REFER_DETAIL.param.patTelno
            );
          }
          if (
            this.GET_REFER_DETAIL.param.agreeId !== null &&
            this.GET_REFER_DETAIL.param.agreeId !== ""
          ) {
            this.form.agreeId.flag = true;
            this.form.agreeId.value = this.GET_REFER_DETAIL.param.agreeId;
          }

          if (
            this.GET_REFER_DETAIL.param.diagNm !== null &&
            this.GET_REFER_DETAIL.param.diagNm !== ""
          ) {
            this.form.diagnosis.flag = true;
            this.form.diagnosis.value = this.GET_REFER_DETAIL.param.diagNm;
          }
          if (
            this.GET_REFER_DETAIL.param.referConts !== null &&
            this.GET_REFER_DETAIL.param.referConts !== ""
          ) {
            this.form.vital.flag = true;
            this.form.vital.value = this.GET_REFER_DETAIL.param.referConts;
          }
          if (
            this.GET_REFER_DETAIL.param.referAttchId !== null &&
            this.GET_REFER_DETAIL.param.referAttchId !== ""
          ) {
            this.form.attachment.flag = true;
            this.form.attachment.source =
              this.GET_REFER_DETAIL.param.referAttchId;
          }
          if (
            this.GET_REFER_DETAIL.param.referAttchNm !== null &&
            this.GET_REFER_DETAIL.param.referAttchNm !== ""
          ) {
            this.form.attachment.filename =
              this.GET_REFER_DETAIL.param.referAttchNm;
          }
          if (
            this.GET_REFER_DETAIL.param.referId !== null &&
            this.GET_REFER_DETAIL.param.referId !== ""
          ) {
            this.form.referId.value = this.GET_REFER_DETAIL.param.referId;
          }
          if (
            this.GET_REFER_DETAIL.param.completeYn !== null &&
            this.GET_REFER_DETAIL.param.completeYn !== ""
          ) {
            this.form.completeYn = this.GET_REFER_DETAIL.param.completeYn;
            if (this.GET_REFER_DETAIL.param.completeYn === "Y") {
              this.isPdf = true;
              this.modifyBtn = "변경사항<br/>저장하기";
              if (this.GET_REFER_DETAIL.param.visitYn.value === "Y") {
                this.form.visitYn.value = this.GET_REFER_DETAIL.param.visitYn;
                this.modifyBtn = "내원완료";
              }
            }
          }
          if (
            this.GET_REFER_DETAIL.param.rcvConts !== null &&
            this.GET_REFER_DETAIL.param.rcvConts !== ""
          ) {
            this.form.rcvConts.value = this.GET_REFER_DETAIL.param.rcvConts;
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    getReplyStatus() {
      this.form.receive.flag = true;
      this.form.doctor.flag = true;
      this.form.receive.value = this.GET_REPLY_STATUS.receive;
      this.form.doctor.value = this.GET_REPLY_STATUS.doctor;
      this.form.rcvDrId.value = this.GET_REPLY_STATUS.drId;
      if (this.GET_REPLY_STATUS.referId !== null) {
        this.form.referId.value = this.GET_REPLY_STATUS.referId;
      }
      if (this.GET_REPLY_STATUS.proObj !== null) {
        this.form.subject.value = this.GET_REPLY_STATUS.proObj;
        this.form.subject.flag = true;
        //console.log("넘어온 진료과 :");
        //console.log(this.GET_REPLY_STATUS);
      }
    },
    initStatement() {
      //console.log("this.GET_REFER_ID:", this.GET_REFER_ID);
      const initState = [];
      if (this.form.visitYn.value !== undefined) {
        this.SET_REFER_DETAIL(initState);
        //console.log("회신체크", this.form.rcvConts.value);
      }
      if (sessionStorage.getItem("isModifyMode") !== null) {
        if (this.GET_REFER_ID !== undefined && this.GET_REFER_ID !== null) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /** 수정폼, 입력폼 플래그 */
    configureType() {
      this.bringAgreeId();
      if (this.initStatement()) {
        if (this.GET_REFER_ID !== undefined && this.GET_REFER_ID !== null) {
          this.bringDetail();
          sessionStorage.setItem("isModifyMode", true);
        } else {
          this.isPdf = false;
          this.bringBasic();
          if (this.GET_REPLY_STATUS.drId !== undefined) {
            this.getReplyStatus();
          }
          this.getCurrentDate();
        }
      } else {
        //console.log("55555555555");
        this.isPdf = false;
        this.bringBasic();
        if (this.GET_REPLY_STATUS.drId !== undefined) {
          this.getReplyStatus();
        }
        this.getCurrentDate();
        /*this.SET_POPUP(true);
        this.popupSet.title = "의뢰내용 조회실패";
        this.popupSet.content =
          "새로고침하여 의뢰서의 내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.cancelBtnText = undefined;
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.nextLink = "/v/request/list";
        this.popupSet.destination = true;*/
      }
    },
    deleteFile() {
      this.form.attachment.isDeleted = 1;
      this.form.attachment.flag = false;
      this.form.attachment.source = null;
      this.form.attachment.url = "";
      this.form.attachment.filename = "";
      document.querySelector("input.file-upload").value = "";
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      //console.log("가져올 파일명: ", getFile[0].name);
      //console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment.source = event.target.files[0];
      /** getFile */
      this.form.attachment.url = getFileUrl;
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.flag = true;
      /*console.log("flag", this.form.attachment.flag);
      console.log("파일", event.target.files[0]);
      console.log("소스", this.form.attachment.source);*/
    },
    attachmentFile() {
      let parentNode = document.querySelector(".attachment");
      //console.log("parentNode", parentNode);

      parentNode.querySelector("input.file-upload").click();
    },
    download_rcvFile(data) {
      //console.log(data);
      //console.log(data.url);
      if (data.url === undefined) {
        var url = "https://www.drrefer.net/api/refer/" + data.fileId;
        var headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };
        var config = {
          responseType: "blob",
          headers: headers,
        };
        axios.get(url, config).then(function (response) {
          var blob = response.data;
          var u = URL.createObjectURL(blob);
          window.open(u, "_blank");
        });
      } else {
        window.open(data.url, "_blank");
      }
    },
    async bringAgreeId() {
      //console.log("in bringAgreeId");
      try {
        await this.BRING_AGREE_ID().then(() => {
          //console.log(this.GET_AGREE_ID);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async bringBasic() {
      try {
        await this.FETCH_BASIC().then(() => {
          this.form.userData.drId = this.GET_BASIC.drId;
          this.form.userData.drNm = this.GET_BASIC.drNm;
          this.form.userData.hospNm = this.GET_BASIC.hospNm;
          //console.log("===============================");
          //console.log(this.GET_BASIC);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "기본정보를 조회하는데 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    receiveDoctor(doctorInfo) {
      this.form.receive.flag = true;
      this.form.receive.hasError = false;
      if (doctorInfo !== null) {
        this.form.rcvDrId.value = doctorInfo.drId;
        this.form.receive.value = doctorInfo.hospNm;
        this.form.doctor.value = doctorInfo.drNm;
        if ((doctorInfo.proObj !== null) & (doctorInfo.proObj !== "")) {
          this.form.subject.value = doctorInfo.proObj;
          this.form.subject.flag = true;
        }
        if ((doctorInfo.hospTel !== null) & (doctorInfo.hospTel !== "")) {
          this.form.rcvHospNo = doctorInfo.hospTel;
        }
      }

      this.form.doctor.flag = true;
      this.form.doctor.hasError = false;
      window.localStorage.removeItem("search_doctor");
    },
    reloadNav() {
      if (!this.showNav) {
        this.showNav = true;
      }
    },
    callHospitalInfo() {
      document.querySelector("body").classList.add("spreaded");
      this.showNav = false;
      this.SET_SEARCH_POPUP_REFORM(true);
    },
    agreementTrans() {
      //console.log("진입");
      //console.log("값", this.isAgreeTransed);

      if (!this.isAgreeTransed) {
        this.callAgreementRequestApi(); //
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "동의요청 확인";
        this.popupSet.content = "이미 동의요청을 전송하였습니다. 재전송할까요?";
        this.popupSet.popType = "custom";
        this.popupSet.cancelBtnText = "취소";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.callFunction = true;
      }
    },
    async callAgreementCheckedApi() {
      try {
        if (
          this.form.agreeId.value === null ||
          this.form.agreeId.value === undefined
        )
          this.form.agreeId.value = "";

        var agreeIdBoo = /^[0-9]{8,14}$/;
        if (!agreeIdBoo.test(this.form.agreeId.value)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "동의요청을 전송하지 못하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }
        await this.BRING_AGREE_RESULT(this.form.agreeId.value).then((data) => {
          //console.log(data);
          //console.log("가져온 동의번호 결과", this.GET_AGREE_RESULT);

          if (data.isnull === true) {
            this.SET_POPUP(true);
            this.popupSet.title = "동의 요청 확인";
            this.popupSet.content =
              "처리된 동의 내역이 없습니다. 동의를 진행해주시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          if (
            this.GET_AGREE_RESULT.rltnType !== null ||
            this.GET_AGREE_RESULT.rltnType !== undefined ||
            this.GET_AGREE_RESULT.rltnType !== ""
          ) {
            this.form.isCheckedAgreement.flag = true;
            this.form.isCheckedAgreement.hasError = false;
            this.popupSet.content = "동의여부가 확인되었습니다.";
            this.agreeResult = this.GET_AGREE_RESULT;
            this.isAgree = true;
          } else {
            this.form.isCheckedAgreement.flag = false;
            this.form.isCheckedAgreement.hasError = true;

            this.form.isCheckedAgreement.errorTxt =
              "동의요청이 서버에 전송되지 않아 동의여부를 확인할 수 없습니다.";
            this.popupSet.content =
              "동의요청이 서버에 전송되지 않아 \n  동의여부를 확인할 수 없습니다. 잠시후 확인해주세요";
          }
          this.SET_POPUP(true);
          this.popupSet.title = "동의요청 확인";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 동의여부를 확인하지 못하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async callAgreementRequestApi() {
      if (this.agreeResult.agreeYn !== undefined) {
        this.agreeResult = [];
      }
      /** APi 액션 후 ->  isPressAgreementRequest*/
      //console.log("api 진입");
      if (
        this.form.phone.flag &&
        this.form.name.flag &&
        this.form.gender.flag &&
        this.form.birth.flag &&
        !(this.form.receive.value === "" || this.form.receive.value === null) &&
        !(this.form.doctor.value === "" || this.form.doctor.value === null)
      ) {
        try {
          //console.log("agree_id : ", this.GET_AGREE_ID);
          //console.log("agree_id : ", this.GET_REFER_DETAIL);
          var payload = {};
          if (this.GET_REFER_DETAIL.length === 0) {
            payload = {
              hpNo: this.form.phone.value.replace(/-/gi, ""),
              agreeId: this.GET_AGREE_ID,
            };
          } else {
            payload = {
              hpNo: this.form.phone.value.replace(/-/gi, ""),
              agreeId: this.GET_REFER_DETAIL.param.agreeId,
            };
          }

          payload.recvHosp = this.form.receive.value;
          payload.recvHpNo = this.form.rcvHospNo;

          //console.log("payload : ", payload);

          // 유효성 검사
          // 1. 휴대폰 번호
          if (payload.hpNo === null || payload.hpNo === undefined)
            payload.hpNo = "";

          var hpNoBoo = /^[0-9]{8,14}$/;

          if (!hpNoBoo.test(payload.hpNo.replace(/\s/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자동의 휴대폰 번호 확인";
            this.popupSet.content = "환자의 휴대폰 번호의 숫자를 확인하세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 동의요청번호
          if (payload.agreeId === null || payload.agreeId === undefined)
            payload.agreeId = "";

          var agreeIdBoo = /^[0-9]{8,14}$/;

          if (!agreeIdBoo.test(payload.agreeId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "동의요청을 전송하지 못하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          await this.BRING_SMS_CODE(payload).then(() => {
            //console.log("SMS-LIST-RESULT", this.GET_SMS_LIST);
            this.form.isPressAgreementRequest.flag = true;
            this.form.agreeId.value = this.GET_SMS_LIST.agreeId;
            this.isAgreeTransed = true; // 동의 내역 확인 버튼 보이게
            /**
            this.form.isCheckedAgreement.flag = true;
            this.form.isCheckedAgreement.hasError = false;
            */
            this.SET_POPUP(true);
            this.popupSet.title = "동의요청";
            this.popupSet.content = "동의요청을 성공적으로 발송하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          });
        } catch (error) {
          console.log("error", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "동의요청을 전송하지 못하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        /**
        const url =
          "https://www.drrefer.net/v/req/agree/" + this.GET_SMS_LIST.encAgreeId;
        window.open(url, "_blank");
         */
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력정보 확인";
        this.popupSet.content =
          "필수입력정보를 기입한 후 동의요청을 수행해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.name.flag) {
          this.form.name.hasError = true;
        }
        if (!this.form.birth.flag) {
          this.form.birth.hasError = true;
        }
        if (!this.form.gender.flag) {
          this.form.gender.hasError = true;
        }
        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
      }
    },
    checkVital() {
      this.form.vital.flag = false;
      this.form.vital.hasError = true;
      const Vital = this.form.vital.value.replace(/\s/gi, "");
      if (Vital === "") {
        this.form.vital.flag = false;
        this.form.vital.errorTxt = "*환자상태를 입력해주세요.";
        return false;
      }
      /*const isVital = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|*|+|?|^|,|-|"|:|~|'|]{5,400}$/;
      if (!isVital.test(Vital)) {
        this.form.vital.errorTxt =
          "*한글 및 영문만 입력할 수 있습니다. 의뢰 내용은 6자 이상 입력되어야 합니다.";
        this.form.vital.flag = false;
        return false;
      }*/
      this.form.vital.flag = true;
      this.form.vital.hasError = false;
      this.form.vital.errorTxt = "";
      return true;
    },
    checkDiagnosis() {
      this.form.diagnosis.flag = false;
      this.form.diagnosis.hasError = true;
      const Diagnosis = this.form.diagnosis.value.replace(/\s/gi, "");
      const code = this.form.code;
      if (Diagnosis === "") {
        //주증상 비움
        if (code.value === "") {
          // 코드확인하여 코드가 비었을떄
          //console.log("diag null and code null");
          this.form.diagnosis.flag = false;
          this.form.diagnosis.hasError = true;
          this.form.diagnosis.errorTxt =
            "*진단명 또는 진단 코드중 하나는 필수로 입력되어야 합니다.";
          code.flag = false;
          code.hasError = true;
          return false;
        } // 코드가 안비었을떄
        else {
          //console.log("diag null and code not null");
          this.form.diagnosis.flag = true;
          this.form.diagnosis.hasError = false;
          code.flag = true;
          code.hasError = false;
          return true;
        }
      } // 주증상 안비움
      else {
        const isDiagnosis = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{2,40}$/;
        if (!isDiagnosis.test(Diagnosis)) {
          this.form.diagnosis.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
          this.form.diagnosis.flag = false;
          return false;
        }
        if (code.value === "") {
          // 코드확인하여 코드가 비었을떄
          //console.log("diag not null and code null");
          this.form.diagnosis.flag = true;
          this.form.diagnosis.hasError = false;
          code.flag = true;
          code.hasError = false;
        } // 코드가 안비었을떄
        else {
          //console.log("diag not null and code not null");
          this.form.diagnosis.flag = true;
          this.form.diagnosis.hasError = false;
          code.flag = true;
          code.hasError = false;
        }
        /*this.form.diagnosis.flag = true;
        this.form.diagnosis.hasError = false;
        this.form.diagnosis.errorTxt = "";*/
        return true;
      }
    },
    checkPhone() {
      this.form.phone.flag = false;
      this.form.phone.hasError = true;
      const phone = this.form.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        this.form.phone.errorTxt = "*핸드폰번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(phone)) {
        this.form.phone.errorTxt = "*-과 숫자만 입력해주세요.";
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        return false;
      }
      this.form.phone.flag = true;
      this.form.phone.hasError = false;
      this.form.phone.errorTxt = "";
      this.form.isPressAgreementRequest.flag = false;
      return true;
    },
    checkDoctor() {
      this.form.doctor.flag = false;
      this.form.doctor.hasError = true;
      const Doctor = this.form.doctor.value.replace(/\s/gi, "");
      if (Doctor === "") {
        this.form.doctor.flag = false;
        this.form.doctor.errorTxt = "*진료의사를 입력해주세요.";
        return false;
      }
      const isDoctor = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isDoctor.test(Doctor)) {
        this.form.doctor.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.doctor.flag = false;
        return false;
      }
      this.form.doctor.flag = true;
      this.form.doctor.hasError = false;
      this.form.doctor.errorTxt = "";
      return true;
    },
    checkName() {
      this.form.name.flag = false;
      this.form.name.hasError = true;
      const Name = this.form.name.value.replace(/\s/gi, "");
      if (Name === "") {
        this.form.name.flag = false;
        this.form.name.errorTxt = "*이름을 입력해주세요.";
        return false;
      }
      const isName = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isName.test(Name)) {
        this.form.name.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.name.flag = false;
        return false;
      }
      this.form.name.flag = true;
      this.form.name.hasError = false;
      this.form.name.errorTxt = "";
      return true;
    },
    checkSubject() {
      this.form.subject.flag = false;
      this.form.subject.hasError = true;
      const Subject =
        this.form.subject.value == undefined
          ? ""
          : this.form.subject.value.replace(/\s/gi, "");
      if (Subject === "") {
        this.form.subject.hasError = false;
        return false;
      }
      const isSubject = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isSubject.test(Subject)) {
        this.form.subject.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.subject.flag = false;
        return false;
      }
      this.form.subject.flag = true;
      this.form.subject.hasError = false;
      this.form.subject.errorTxt = "";
      return true;
    },
    checkCode() {
      this.form.code.flag = false;
      this.form.code.hasError = true;
      const Code = this.form.code.value.replace(/\s/gi, "");
      const diagnosis = this.form.diagnosis;
      if (Code === "") {
        if (diagnosis.value === "") {
          //console.log("code null and diag null");
          this.form.code.flag = false;
          this.form.code.hasError = true;
          diagnosis.flag = false;
          diagnosis.hasError = true;
          return false;
        } else {
          //console.log("code null and diag not null");
          this.form.code.flag = true;
          this.form.code.hasError = false;
          diagnosis.flag = true;
          diagnosis.hasError = false;
          return true;
        }
      } else {
        const isCode = /^[a-zA-Z][0-9]{1,2}$/;
        if (!isCode.test(Code)) {
          //console.log("진단코드에서의 오류입니다.");
          this.form.code.flag = false;
          return false;
        }
        if (diagnosis.value === "") {
          //console.log("code not null and diag null");
          this.form.code.flag = true;
          this.form.code.hasError = false;
          diagnosis.flag = true;
          diagnosis.hasError = false;
        } else {
          //console.log("code not null and diag not null");
          this.form.code.flag = true;
          this.form.code.hasError = false;
          diagnosis.flag = true;
          diagnosis.hasError = false;
        }
        /*this.form.code.flag = true;
      this.form.code.hasError = false;
      this.form.code.errorTxt = "";*/
        return true;
      }
    },

    checkBirth() {
      this.form.birth.flag = false;
      this.form.birth.hasError = true;
      const birth = this.form.birth.value.replace(/\s/gi, "");
      if (birth === "") {
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        this.form.birth.errorTxt = "*생년월일을 입력해주세요.";
        return false;
      }
      const isBirth = /^[0-9]{6}$/;
      if (!isBirth.test(birth)) {
        this.form.birth.errorTxt =
          "*YYYY-MM-DD 형식에 맞추어 숫자만 입력해주세요.";
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        return false;
      }
      this.form.birth.flag = true;
      this.form.birth.hasError = false;
      this.form.birth.errorTxt = "";
      return true;
    },
    checkReceive() {
      this.form.receive.flag = false;
      this.form.receive.hasError = true;
      const Receive = this.form.receive.value.replace(/\s/gi, "");
      if (Receive === "") {
        this.form.receive.flag = false;
        this.form.receive.errorTxt = "*수신병원을 입력해주세요.";
        return false;
      }
      const isReceive = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isReceive.test(Receive)) {
        this.form.receive.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.receive.flag = false;
        return false;
      }
      this.form.receive.flag = true;
      this.form.receive.hasError = false;
      this.form.receive.errorTxt = "";
      return true;
    },
    initFlag() {},
    checkFlag() {
      if (
        (!this.form.diagnosis.flag && !this.form.code.flag) ||
        !this.form.vital.flag ||
        !this.form.receive.flag ||
        !this.form.doctor.flag ||
        !this.form.name.flag ||
        !this.form.birth.flag ||
        !this.form.gender.flag ||
        !this.form.phone.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.diagnosis.flag && !this.form.code.flag) {
          this.popupSet.content =
            "진단명 또는 주증상, 진단코드중 하나는 필수 입력해야 합니다.";
        }
        /*if (!this.form.diagnosis.flag) {
          this.form.diagnosis.hasError = true;
        }*/
        if (!this.form.vital.flag) {
          this.form.vital.hasError = true;
        }
        if (!this.form.receive.flag) {
          this.form.receive.hasError = true;
        }
        if (!this.form.doctor.flag) {
          this.form.doctor.hasError = true;
        }
        if (!this.form.name.flag) {
          this.form.name.hasError = true;
        }
        if (!this.form.birth.flag) {
          this.form.birth.hasError = true;
        }
        if (!this.form.gender.flag) {
          this.form.gender.hasError = true;
        }
        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
        /*if (!this.form.isCheckedAgreement.flag) {
          this.form.isCheckedAgreement.hasError = true;
        }*/
        return false;
      }
      return true;
    },
    async next() {
      //console.log(this.sendCount);
      if (this.sendCount === 0) {
        //console.log("체크테스트", this.GET_REPLY_STATUS.referId !== undefined);

        if (this.checkFlag()) {
          const referParam = {
            rcvDrId: this.form.rcvDrId.value,
            sndDrId: this.form.userData.drId,
            sndDrNm: this.form.userData.drNm,
            sndHospNm: this.form.userData.hospNm,
            rcvHospNm: this.form.receive.value,
            rcvDrNm: this.form.doctor.value,
            diagCd: this.form.code.value,
            rcvParts: this.form.subject.value,
            patNm: this.form.name.value,
            patTelno: this.form.phone.value.replace(/-/gi, ""),
            diagNm: this.form.diagnosis.value,
            referConts: this.form.vital.value,
            agreeId: this.GET_AGREE_ID,
            completeYn: "Y",
            isDeletedAttchId: 0,
            /** completed */
          };
          /*console.log(
            "발신 : " + referParam.sndHospNm + " 수신 : " + referParam.rcvHospNm
          );
          console.log(
            "발신 : " + referParam.sndDrId + " 수신 : " + referParam.rcvDrId
          );*/
          if (referParam.sndHospNm === referParam.rcvHospNm) {
            this.SET_POPUP(true);
            this.popupSet.title = "ERROR";
            this.popupSet.content = "수신병원과 발신병원이 같을 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return 0;
          }
          if (this.form.birth.value !== "" && this.form.gender.value !== "") {
            referParam.patBirth =
              this.form.birth.value + "-" + this.form.gender.value;
          }
          if (this.form.referId.value !== "") {
            referParam.referId = this.form.referId.value;
          }

          console.log("referParam", referParam);

          // 유효성 검사
          // 1. 발신아이디
          if (referParam.sndDrId === null || referParam.sndDrId === undefined)
            referParam.sndDrId = "";

          var sndDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

          if (!sndDrIdBoo.test(referParam.sndDrId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 수신 아이디
          if (referParam.rcvDrId === null || referParam.rcvDrId === undefined)
            referParam.rcvDrId = "";

          var rcvDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

          if (!rcvDrIdBoo.test(referParam.rcvDrId)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 3. 수신자명
          if (referParam.rcvDrNm === null || referParam.rcvDrNm === undefined)
            referParam.rcvDrNm = "";

          var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{1,100}$/;

          if (!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 4. 진료과목
          if (referParam.rcvParts === null || referParam.rcvParts === undefined)
            referParam.rcvParts = "";

          if (
            referParam.rcvParts.length < 1 ||
            referParam.rcvParts.length > 100
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "진료과목 확인";
            this.popupSet.content =
              "100자 이내의 진료과목을 입력해주시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // // 5. 동의번호
          // if(referParam.agreeId === null
          // || referParam.agreeId === undefined)
          // referParam.agreeId = "";

          // var agreeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

          // if(!agreeIdBoo.test(referParam.agreeId)){
          //   this.SET_POPUP(true);
          //   this.popupSet.title = "서버요청 실패";
          //   this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          //   this.popupSet.popType = "warn";
          //   this.popupSet.cancelBtnText = "확인";
          //   return;
          // }

          // 6. 환자명
          if (referParam.patNm === null || referParam.patNm === undefined)
            referParam.patNm = "";

          var patNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{2,20}$/;

          if (!patNmBoo.test(referParam.patNm.replace(/\s/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자명 확인";
            this.popupSet.content =
              "환자명의 글자 수 및 특수문자를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 7. 환자생년월일
          if (referParam.patBirth === null || referParam.patBirth === undefined)
            referParam.patBirth = "";

          var patBirthBoo = /^[0-9]{1,8}$/;

          if (!patBirthBoo.test(referParam.patBirth.replace(/-/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자생년월일 확인";
            this.popupSet.content =
              "환자생년월일의 글자 수 및 특수문자를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 8. 환자연락처
          if (referParam.patTelno === null || referParam.patTelno === undefined)
            referParam.patTelno = "";

          var patTelnoBoo = /^[0-9]{8,14}$/;

          if (!patTelnoBoo.test(referParam.patTelno.replace(/-/gi, ""))) {
            this.SET_POPUP(true);
            this.popupSet.title = "환자연락처 확인";
            this.popupSet.content = "환자연락처를 확인하시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 9. 진단코드
          if (referParam.diagCd === null || referParam.diagCd === undefined)
            referParam.diagCd = "";

          var diagCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

          if (!diagCdBoo.test(referParam.diagCd)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 10. 진단명
          if (referParam.diagNm === null || referParam.diagNm === undefined)
            referParam.diagNm = "";

          if (referParam.diagNm.length < 0 || referParam.diagNm.length > 200) {
            this.SET_POPUP(true);
            this.popupSet.title = "진단명 확인";
            this.popupSet.content = "진단명을 200자 이내로 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 11. 환자상태/의뢰내용
          if (
            referParam.referConts === null ||
            referParam.referConts === undefined
          )
            referParam.referConts = "";

          if (
            referParam.referConts.length < 0 ||
            referParam.referConts.length > 3000
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "의뢰첨부파일번호 확인";
            this.popupSet.content =
              "환자상태/의뢰내용을 3000자 이내로 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 12. 의뢰첨부파일번호
          if (
            referParam.referAttchId === null ||
            referParam.referAttchId === undefined
          )
            referParam.referAttchId = "";

          if (
            referParam.referAttchId.length < 0 ||
            referParam.referAttchId.length > 40
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 13. completeYn
          if (
            referParam.completeYn === null ||
            referParam.completeYn === undefined
          )
            referParam.completeYn = "";

          var completeYnBoo = /^[A-Z]{0,1}$/;

          if (!completeYnBoo.test(referParam.completeYn)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          const transedReferParam = JSON.stringify(referParam);
          const transData = new FormData();
          transData.append("referParam", transedReferParam);
          if (
            this.form.attachment.source !== "" &&
            this.form.attachment.source !== null
          ) {
            transData.append("referFile", this.form.attachment.source);
          }
          if (this.GET_REPLY_STATUS.referId !== undefined) {
            try {
              this.sendCount++;
              await this.REFER_REPLY(transData).then((data) => {
                this.SET_POPUP(true);
                this.popupSet.title = "진료의뢰서 등록완료";
                this.popupSet.content =
                  "진료의뢰서를 성공적으로 등록하였습니다.";
                this.popupSet.popType = "refer";
                this.popupSet.pdfParam = data.referId;
                this.popupSet.confirmBtnText = "확인";
                this.popupSet.nextLink = "/v/mypage/request/0";
                this.popupSet.destination = true;
              });
              /**
               * request After 에 필요한 내용 기입하기
               * own찾기
               */
            } catch (error) {
              this.sendCount = 0;
              //console.log("error: ", error);
              if (error.data.status !== 5000 && error.data.status !== 5002) {
                this.SET_POPUP(true);
                this.popupSet.title = "서버요청 실패";
                this.popupSet.content = "진료의뢰서 등록을 실패하였습니다.";
                this.popupSet.popType = "warn";
                this.popupSet.cancelBtnText = "확인";
              }
            }
          } else if (this.form.referId.value !== "") {
            try {
              this.sendCount++;
              await this.REFER_MODIFY(transData).then((data) => {
                this.SET_POPUP(true);
                this.popupSet.title = "진료의뢰서 등록완료";
                this.popupSet.content =
                  "진료의뢰서를 성공적으로 등록하였습니다.";
                this.popupSet.popType = "refer";
                this.popupSet.pdfParam = data.referId;
                this.popupSet.confirmBtnText = "확인";
                this.popupSet.nextLink = "/v/mypage/request/0";
                this.popupSet.destination = true;
              });
              /**
               * request After 에 필요한 내용 기입하기
               */
            } catch (error) {
              this.sendCount = 0;
              console.log("error: ", error);
              if (error.data.status !== 5000 && error.data.status !== 5002) {
                this.SET_POPUP(true);
                this.popupSet.title = "서버요청 실패";
                this.popupSet.content = "진료의뢰서 등록을 실패하였습니다.";
                this.popupSet.popType = "warn";
                this.popupSet.cancelBtnText = "확인";
              }
            }
          } else {
            try {
              this.sendCount++;
              await this.REFER_SUBMIT(transData).then((data) => {
                this.SET_POPUP(true);
                this.popupSet.title = "진료의뢰서 등록완료";
                this.popupSet.content =
                  "진료의뢰서를 성공적으로 등록하였습니다.";
                this.popupSet.popType = "refer";
                this.popupSet.pdfParam = data.referId;
                this.popupSet.confirmBtnText = "확인";
                this.popupSet.nextLink = "/v/mypage/request/0";
                this.popupSet.destination = true;
              });
              /**
               * request After 에 필요한 내용 기입하기
               */
            } catch (error) {
              this.sendCount = 0;
              console.log("error: ", error);
              if (error.data.status !== 5000 && error.data.status !== 5002) {
                this.SET_POPUP(true);
                this.popupSet.title = "서버요청 실패";
                this.popupSet.content = "진료의뢰서 등록을 실패하였습니다.";
                this.popupSet.popType = "warn";
                this.popupSet.cancelBtnText = "확인";
              }
            }
          }
        }
      } else {
        //console.log("asd");
      }
    },
  },
  watch: {},
  destroyed() {
    this.setCount = false;
    if (sessionStorage.getItem("isModifyMode") !== null) {
      sessionStorage.removeItem("isModifyMode");
    }
    if (this.GET_REFER_ID !== null) {
      this.SET_REFER_ID(null);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/requestform";
/* @import "@/assets/css/sideContext"; */
@import "@/assets/scss/RequestDocument.scss";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
